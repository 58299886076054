
.cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
    background-color: #f0f0f0;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
    background-color: #14C7EA;
}

.cs-button--send {
    color: #14C7EA;
}

.cs-message__content {
    padding: 0.15em 0.6em;
}

.cs-message-input__content-editor-container {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
}

.cs-message-input__content-editor-wrapper {
    border-color: #35d1f5;
    box-shadow: 0rem 0rem 0rem 0.125rem rgba(129, 227, 249, 1);
    outline: 0;
    border-radius: 0.5rem;
    background-color: #fff;
}

.cs-message-input__content-editor {
    background-color: #fff;
}

.cs-message-separator {
    color: #adb5bd;
    opacity: 1;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
}

.cs-message-separator::before, .cs-message-separator::after {
    background-color: #f0f0f0;
}

.cs-chat-container .cs-message-input {
    border-top: 1px solid #f0f0f0;
}
